/* .Hero{
    display: flex;
    width: 100%;
    height: 500px;
}

.Hero img{
    width: 100%;
    height: 100%;
} */

.Hero {
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 0px;
    margin-top: 50px;
    position: relative;
  }

  .Hero h1 {
    /* color: rgb(240, 204, 1); */
    color: white;
    /* color: #F40269; */
    /* color: goldenrod; */
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    font-size: 3rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* text-shadow: 
      1px 1px 0px #000,
      2px 2px 0px #000, 
      3px 3px 0px #000, 
      4px 4px 0px #000, 
      5px 5px 0px #000; */
    /* background-color: rgba(128, 0, 128, 0.7); */
    background: linear-gradient(to bottom , rgba(255, 0, 43, 0.7) , rgba(128, 0, 128, 0.7));
    /* background-color: rgba(0, 0, 0, 0.473); */
    /* background-color: rgba(128, 0, 128, 0.5); */
  }
  

  .carousel, .slide img{
    width: 100%;
    height: 500px;
  
  }

 
  
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  
  .carousel .control-arrow:hover {
    opacity: 1;
  }
  
  .carousel .control-dots .dot {
    background-color: white;
    box-shadow: none;
  }
  
  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:focus {
    background-color:white;
    box-shadow: none;
  }


 


  @media screen and (max-width:884px) {
    .carousel, .slide img{
      width: 100%;
      height: 400px;
    
    }

    .Hero h1{
      font-size: 2rem;
    }
  }


  @media screen and (max-width:768px) {
    .carousel, .slide img{
      width: 100%;
      height: 350px;
    
    }

    .Hero h1{
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width:428px) {
    .carousel, .slide img{
      width: 100%;
      height: 200px;
    
    }

    .Hero h1{
      font-size: 1.2rem;
      bottom: 30px;
    }
  }

  @media screen and (max-width:360px) {
    .carousel, .slide img{
      width: 100%;
      height: 200px;
    
    }

    .Hero h1{
      font-size: 1rem;
    }
  }

  @media screen and (max-width:320px) {
    .carousel, .slide img{
      width: 100%;
      height: 170px;
    
    }

    .Hero{
      margin-top: 40px;
    }

    .Hero h1{
      font-size: 1rem;
    }
  }



  
/* 
  @media screen and (max-width:884px) {
    .Hero{
      height: 400px;
      padding: 0px 15px;
    }
    .carousel .slide img{
  
      height: 451px
    }
    
  }

  @media screen and (max-width:768px) {
    .Hero{
      margin-top: 0px;
    }
  }



  @media screen and (max-width:428px) {
    .Hero{
      height: 170px;
      padding: 0px 5px;
      margin-top: 5px;
    }

    .carousel .slide img{
   
      height: 221px
    }
  }

  @media screen and (max-width:320px) {
 
    
  } */