.GalleryPageHeaderWrap{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px;
    background-image: url("../images/gallerypicimg.png");
    background-position: center;
    position: relative;

    /* background-size: cover; */
    
}

.GalleryPageHeaderWrap::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.GalleryPageHeaderWrap h2 {
    
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    position: relative;
}



.galleries {
    padding: 80px;
    /* background: red; */
    border-radius: 10px;
}

.galleries h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(255, 0, 43, 0.7);
    font-size: 2rem;
}

.gallery-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.gallery-card {
    background: #f0f0f0;
    /* background-color: rgba(255, 192, 203, 0.3); */
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gallery-card p{
    /* color: purple; */
    color: #4a148c;
}

.gallery-card img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}



.GalleryButtonWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;    /* padding: 50px; */
}

.GalleryButton {
    padding: 10px 20px;
    background-color: rgba(255, 0, 43, 0.7);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    
}

.GalleryButton:hover {
    background-color: rgba(255, 0, 43, 0.9);
}

.GalleryPageCardsWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap:20px

}

@media (max-width: 600px) {
    .galleries {
        padding: 15px;
    }

    .gallery-card {
        width: 100%;
    }
}
