.footer {
    /* background-color: #333; */
    /* background-image: url("../Images/HeroPic1.jpeg"); */
    color: white;
    padding: 20px 0;
    /* text-align: cen; */
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
  }

  .footer::before{
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.9); */
    background:linear-gradient(to bottom, #F40269, #810065 );
    content: "";
    width: 100%;
    height: 100%;
  }

  .footerdisappear{
    display: none;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
  }
  
  .footer-links, .footer-contact, .footer-payment, .footer-social {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin: 10px;
    /* gap: 10px; */
  }

  .footer-payment img{
    width: 100px;
    margin: 10px;
    margin-left: 20px;
    cursor: pointer;
  }

  .footer-links{
    gap:7px;
  }

  .footer-links h4{
    text-decoration: underline;
  }
  
  .footer-link, .social-link {
    color: white;
    text-decoration: none;
  }

  .social-link{
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .footer-link:hover, .social-link:hover {
    text-decoration: underline;
  }
  
  .footer-contact p, .footer-payment p, .footer-payment ul, .footer-payment p {
    margin: 5px 0;
    align-items: center;
    justify-content: center;
  }

  .footer-contact p, .footer-links a{
    font-size: 0.9rem;
  }

.CopyRight{
  color:"white";
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 
  margin-bottom: 30px;
  flex-direction: column;
  /* font-size: 1.2rem; */
  gap:8px;
  text-align: center;
}

@media screen and (max-width:884px){
  
  .footer-payment ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .footer-payment img{
    margin-left: 0px;
    width: 100px;
    height: 40px;
  }
  .footer-payment{
    margin-right:300px;
  }

  .footer-social{
    flex-direction: row;
    gap:20px;
    margin-top: 50px;
  }
}

.footer-social a{
  /* font-size: 1.2rem; */
}


  @media screen and (max-width:768px){
    .footer{
      padding-bottom: 100px;
    }

    .footer-payment{
      margin-right: 250px;
    }
  }
  
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
    }
  }

  @media (max-width: 428px) {
    .footer-social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 0px;
    }
    .footer-container{
      gap:50px;
      padding: 20px;
    }
    .footer-payment{
      margin-right: 40px;
    }

    .CopyRight{
      padding: 0% 3%;
    }

    
  }
  
  @media screen and (max-width:375px) {
    .footer-payment ul{
      flex-direction: column;
    }
  }