.get-involved {
    padding: 20px;
    background-color: rgba(255, 192, 203, 0.2);
    /* border-radius: 10px; */
    text-align: center;
}

.get-involved h2 {
    margin-bottom: 20px;
    /* color: rgba(255, 0, 43, 0.7); */
    /* color: rgba(128, 0, 128, 0.7); */
    color: rgba(255, 0, 43, 0.7);
}

.get-involved p{
    color: #4a148c;
    text-align: center;
    margin-bottom: 20px;
}

.get-involved form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.get-involved input,
.get-involved select {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    /* color: purple; */
    color: #4a148c;
}

.get-involved select{
    cursor: pointer;
}

.get-involved button {
    padding: 10px 20px;
    background-color: rgba(255, 0, 43, 0.7);
    /* background-color: rgba(128, 0, 128, 0.7); */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.get-involved button:hover {
    background-color: rgba(255, 0, 43, 0.9);
}

@media (max-width: 600px) {
    .get-involved {
        padding: 15px;
    }

    .get-involved input,
    .get-involved select {
        width: 100%;
    }
}
