*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.color{
  color:#F40269;
  color:#810065;
}

.WhatsAppIcon{
  position: fixed;
  z-index: 99;
  bottom: 50px;
  right: 50px;
  width: 70px;
  box-shadow: 0px 4px 5px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width:768px) {
  .WhatsAppIcon{
      width: 50px;
      bottom: 120px;
      right: 30px;
  }

  
}

@media screen and (max-width:428px) {
  .WhatsAppIcon{
      bottom: 5%;
      right: 5%;
  }
}