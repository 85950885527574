/* Intro.css */

.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
    /* background-color: #f9f9f9; */
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .intro-heading {
    font-size: 2.5rem;
    color: rgba(255, 0, 43, 0.7);
    text-align: center;
    margin-bottom: 20px;
    /* text-shadow: 1px 1px 2px #000; */
    text-align: center;
  }
  
  .intro-responsive-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .intro-content {
 
    font-size: 1.1rem;

    /* color: purple; */
    color: #4a148c;
    max-width: 800px;
    line-height: 1.6;
    text-align: justify;
    padding: 0 20px;
    text-align: center;
  }

  .intro-responsive-container button{
    margin-top: 20px;
    background-color: rgba(255, 0, 43, 0.7);
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }

  .intro-responsive-container button:hover{
    background-color: rgba(255, 0, 43, 0.9);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .intro-heading {
      font-size: 2rem;
    }
  
    .intro-content {
      font-size: 1rem;
      padding: 0 10px;
    }
  }
  
  @media (max-width: 480px) {
    .intro-heading {
      font-size: 1.5rem;
    }
  
    .intro-content {
      font-size: 0.9rem;
      padding: 0 5px;

    }

    .intro-container{
      padding: 5px;
      padding: 50px 5px;
    }
  }
  