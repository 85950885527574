.FounderMessageWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 50px; */
}

.FounderMessageWrap p{
    width: 50%;
    padding: 5%;
    /* color:purple; */
    color: #4a148c;
    font-size: 1.1rem;
    
}

.FounderMessageWrap img{
    width: 50%;
}

@media screen and (max-width:768px) {
    .FounderMessageWrap{
        margin-top: 65px;
        flex-direction: column-reverse;
    }

    .FounderMessageWrap p{
        width: 100%;
        font-size: 1rem;
    }
    .FounderMessageWrap img{
        width: 100%;
    }
}