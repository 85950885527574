

.ContactFormWrap{

    padding: 100px 0px;
    background-color: rgba(211, 211, 211, 0.1);
    background-color: rgba(0, 0, 255, 0.1);
    width: 100%;
    background-image: url("../images/contactImg.jpeg");
    background-size: cover;
    position: relative;
}


.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
    align-items: center;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    background-image: url("../images/contactImg.jpeg");
    background-size: contain;
  
  }

  .contact-form-container::before{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }


  .contact-form-container h2 {
    text-align: center;
    color: #4a148c;

    margin-bottom: 20px;
    position: relative;
  }


  
  .form-group {
    margin-bottom: 15px;
    position: relative;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #4a148c;
    position: relative;
  }
  
  .form-group input,
  .form-group textarea {
    width: 500px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form-container button {
    width: 100%;
    padding: 10px;
    background-color:rgba(255, 0, 43, 0.7);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    position: relative;
  }
  
  .contact-form-container button:hover {
    background-color:rgb(255, 0, 43);
  }

  .contact-form-container img{
    width: 100px;
    display: block;
  }

 




@media screen and (max-width:884px) {
  .ContactFormWrap{
    padding: 200px 0px;
  }
}

  @media (max-width: 600px) {
    .contact-form-container {
      padding: 10px;
      padding-top: 50px;
      margin-top: 100px;
      margin-bottom: 50px;
  
    }
  
  .contact-form-container button {
      font-size: 14px;
    }
  }

  @media screen and (max-width:428px) {
    .ContactFormWrap{
      padding: 20px 10px;
    } 
    .contact-form-container{
      width: 100%;

    }
    .form-group input,
  .form-group textarea {
   width: 300px;
  }
    
  }

  @media screen and (max-width:360px) {
   
    .form-group input,
  .form-group textarea {
   width: 250px;
  }
    
  }
  