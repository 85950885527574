.get-inspired {
    padding: 80px;
    background-color: rgba(255, 192, 203, 0.2);
}

.getInspiredHeader{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px;
    background-image: url("../images/inspImg.png");
    background-position: center;
    position: relative;
    
}

.getInspiredHeader::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.getInspiredHeader h2, .get-inspired h2 {
    
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    position: relative;
}

.get-inspired h2{
    color: rgba(255, 0, 43,0.7);
}

.get-inspiredP{
    text-align: center;
    margin-bottom: 50px;
    color: #4a148c;
    font-weight: 400;
    font-size: 1.3rem;
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.blog-card {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.blog-card img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;

}

.blog-cardText{
    width: 100%;
    height: 120px;
}

.blog-card h3{
    color: #4a148cbe;
}

.blog-card p{
    color: #4a148c;
    font-size: 0.9rem;
}

.blog-card button {
    padding: 5px 10px;
    background-color: white;
    border: 1px solid rgb(255, 0, 43);
    border: 1px solid #4a148c;
    color: #4a148c;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
    margin-right: 10px;
}

.blog-card button:hover {
    background-color: #685580;
    color: white;
}




@media (max-width: 600px) {
    .get-inspired {
        padding: 15px;
    }

    .blog-card {
        width: 100%;
    }
}
