.blog-detail {
    max-width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
}

.blog-detail-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.blog-detail h2 {
    margin-top: 20px;
    font-size: 2em;
    color: rgba(255, 0, 43, 0.7);
}

.blog-detail p {
    margin-top: 10px;
    line-height: 1.6;
    color: #666;
    color:#4a148c;
}


.BlogDetailShare{
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #4a148c;
    color: #4a148c;
    background-color: white;
    cursor: pointer;
}

.BlogDetailShare:hover{
    background-color: #685580;
    color: white;
}


@media (max-width: 768px) {
    .blog-detail {
        padding: 15px;
    }

    .blog-detail h2 {
        font-size: 1.5em;
    }

    .blog-detail p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .blog-detail {
        padding: 10px;
    }

    .blog-detail h2 {
        font-size: 1.2em;
    }

    .blog-detail p {
        font-size: 0.9em;
    }
}
