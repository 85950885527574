.BackButtonWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
  
}

.BackButtonWrap button{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 0, 43, 0.651); */
    background-color:white;
    color: rgba(255, 0, 43,0.8);
    /* color: white; */
    border: 1px solid rgba(255, 0, 43,0.8);
    /* border: none; */
    padding: 5px 10px;
    border-radius: 10px;
    gap: 5px;
    cursor: pointer;
}