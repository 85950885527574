

.ContactFormWrap2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
    background-color: rgba(211, 211, 211, 0.1);
    background-color: rgba(0, 0, 255, 0.1);
    width: 100%;
    background-image: url("../images/contactImg.jpeg");
    background-size: cover;
    position: relative;
}


.contact-form-container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
    /* align-items: center; */
    width: 50%;
    /* margin: 0 auto; */
    /* padding: 50px; */
    padding: 150px 50px;
    background-color: #f9f9f9;
    border-radius: 8px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    background-image: url("../images/FounderPic3.png");
    background-size: contain;
    /* background-position: center; */

  
  }

  .contact-form-container2::before{
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.8); */
    position: absolute;
    top: 0;
    left: 0;
    content: "";

  }


  .contact-form-container2 h2 {
    text-align: center;
    /* color: #4a148c; */
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100px;
    margin-bottom: 20px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
  }


  
  .form-group2 {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    /* background-color: red; */
  }
  
  .form-group2 label {
    /* display: block; */
    margin-bottom: 5px;
    color: #4a148c;
    position: relative;
    width: 100%;
    font-weight: bold;
  }
  
  .form-group2 input,
  .form-group2 textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    height: 50px;
    border: 0.5px solid rgba(128, 0, 128, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-group2 input::placeholder, .form-group2 textarea::placeholder{
    color:purple;
    font-style: italic;
    font-size: 1rem;
  }
  
  .form-group2 textarea {
    resize: vertical;
    min-height: 50px;
  }
  
  .contact-form-container2 button {
    width: 100%;
    padding: 10px;
    background-color:rgba(255, 0, 43, 0.7);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    height: 50px;
  }
  
  .contact-form-container2 button:hover {
    background-color:rgb(255, 0, 43);
  }

  .contact-form-container2 img{
    width: 100px;
    display: block;
  }



/*   
  .contact-form-container2 h2 {
    text-align: center;
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 20px;
    position: relative;
  } */



/* @media screen and (max-width:884px) {
  .ContactFormWrap2{
    padding: 200px 0px;
  }
} */

@media screen and (max-width:884px) {
    .contact-form-container2{
        width: 70%;
    }
}


@media screen and (max-width:768px) {
    .contact-form-container2{
        width: 100%;
        height: 100%;
    }
    .ContactFormWrap2{
        padding: 0px 0px;
    }
}



@media screen and (max-width:428px) {
    .contact-form-container2{
        padding: 100px 30px;
        /* padding: 40px; */
        padding-bottom: 50px;
    }
    .form-group2{
        margin-bottom: 20px;
    }
}

/* 
  @media (max-width: 600px) {
    .contact-form-container2 {
      padding: 10px;
      padding-top: 50px;
      margin-top: 100px;
      margin-bottom: 50px;
    }
  
  .contact-form-container2 button {
      font-size: 14px;
    }
  }

  @media screen and (max-width:428px) {
    .ContactFormWrap2{
      padding: 20px 10px;
    } 
    .contact-form-container2{
      width: 100%;

    }
    .form-group2 input,
  .form-group2 textarea {
   width: 300px;
  }
    
  }

  @media screen and (max-width:360px) {
   
    .form-group2 input,
  .form-group2 textarea {
   width: 250px;
  }
    
  }
   */