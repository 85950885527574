
.TestimoniesHeader{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px;
    background-image: url("../images/testimonyImg.png");
    background-position: center;
    background-size: cover;
    position: relative;

    /* background-size: cover; */
    
}

.TestimoniesHeader::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.TestimoniesHeader h2{
    
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    position: relative;
}



.TestimoniesWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 80px;
    flex-direction: column;
}

.testimonies-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.testimonies-container h2{
    color: rgba(255, 0, 43,0.7);
    font-size: 2rem;
}

/* .testimonies {
    padding: 20px;
    background: #e6f7ff;
    border-radius: 10px;
    text-align: center;
   
}

.testimonies h2 {
    margin-bottom: 20px;
   
} */

/* .testimonies-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
} */

.testimony-card {
    background-color: rgba(255, 192, 203, 0.4);
    background-color:whitesmoke;
    padding: 10px;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: center;
    /* color: rgba(128, 0, 128, 0.7); */
    color: #4a148c;
}

.testimony-card img {
    max-width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .testimonies-container {
        /* padding: 15px; */
    }

    .testimony-card {
        width: 100%;
    }
}


@media screen and (max-width:428px) {
    .TestimoniesWrap{
        padding: 10px;
        padding: 50px 20px;
        /* background-color: red; */
    }
}