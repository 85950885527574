

section {
    padding: 50px 20px;
    text-align: center;
    /* background-color: red; */
}


.faq-item{
    /* background: #f8f8f8; */
    border-radius: 10px;
    padding: 20px;
    width: 30%; /* Adjust width based on number of items per row */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    background-color: whitesmoke;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}



.faq-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* background-color: red; */
}

.faq{
    /* background-color: rgba(173, 216, 230, 0.2); */
    /* background-color: rgb(250, 250, 255); */
    padding-top: 100px;
    
}
.faq h2{
    color: rgba(255, 0, 43, 0.7);
    font-size: 2rem;

}

.faq-item h3{
    color: rgba(128, 0, 128, 0.7);
    color: #4a148cbe;
}


.faq-item h3 {
    margin: 15px 0 10px;
}

.faq-item p {
    /* color: #666; */
    color: purple;
    color: #4a148c;
    font-size: 0.9rem;
}

.faq-item:hover {
    transform: translateY(-10px);
}


/* Responsive styling */
@media (max-width: 1200px) {
    .faq-item {
        width: calc(33.333% - 20px); /* Three in a row */
    }
}

@media (max-width: 768px) {
    .faq-item{
        width: calc(50% - 20px); /* Two in a row */
    }
}

@media (max-width: 480px) {
    .faq-item{
        width: 100%; /* One in a row */
    }
}
